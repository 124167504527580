import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Lightbox from 'react-image-lightbox';

export default class Images extends React.Component {

  constructor( props ) {
		super( props );

		this.state = {

			images: [
        '242130977_1440623459653943_4056016997494229292_n.jpeg',
        '242100526_1440624319653857_6000671531420086418_n.jpeg',
        'galerie-foto-maximum-rock-festival-2021-ziua-2_4cbffd.jpeg',
        '242049329_1440624159653873_5071832868044358609_n.jpeg',
        '242059133_1440623442987278_2189600315749251192_n.jpeg',
        '242172025_4492520477502866_2329538153585251713_n.jpeg',
        '242010336_1440624222987200_3024559284761416892_n.jpeg',
        '242008989_1440624339653855_4335986925448487032_n.jpeg',
        '242085418_1440623539653935_7582129965478072216_n.jpeg',
        '242257339_4492520650836182_127493833068563435_n.jpeg',
        'galerie-foto-maximum-rock-festival-2021-ziua-2_56cedd.jpeg',
        'galerie-foto-maximum-rock-festival-2021-ziua-2_61f0f9.jpeg',
        '242140920_4492520590836188_2922151578783449692_n.jpeg',
        '241984078_1440623422987280_8234752410006376776_n.jpeg',
        '242255110_4492522064169374_4230420399908523536_n.jpeg',
        'IMG_5689.jpeg',
        'IMG_5693.jpeg',
        'IMG_5779.jpeg',
        'galerie-foto-maximum-rock-festival-2021-ziua-2_651016.jpeg',
        'IMG_5756.jpeg',
        'IMG_5649.jpeg'
      ],
      photoIndex: 0,
      isOpen: false,
		};
  }

  render() {
    const { images, photoIndex, isOpen } = this.state;

		return (
			<Row id="images">
				 { this.state.images.map((img, index) => {
				 	return <Col md={4} key={index}><img src={'img/live/thumbs/' + img} alt="Maximum Rock 2021" onClick={() => this.setState({ isOpen: true, photoIndex: index })}/></Col>
				 }) }
       {isOpen && (
           <Lightbox
             mainSrc={'img/live/' + images[photoIndex]}
             nextSrc={images[(photoIndex + 1) % images.length]}
             prevSrc={images[(photoIndex + images.length - 1) % images.length]}
             onCloseRequest={() => this.setState({ isOpen: false })}
             onMovePrevRequest={() =>
               this.setState({
                 photoIndex: (photoIndex + images.length - 1) % images.length,
               })
             }
             onMoveNextRequest={() =>
               this.setState({
                 photoIndex: (photoIndex + 1) % images.length,
               })
             }
           />
         )}
      </Row>
		);
	}
}
